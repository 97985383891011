import React from 'react';
import PageTitle from '../components/titles/screenTitle.js';
import Title from '../components/titles/simpleTitle.js';
import Description from '../components/blocks/descriptionForArt.js';
import ArtList from '../components/cards/artCardListForArtItem.js';
import AppBar from '../components/appBar/appBar.js';
import Footer from '../components/footer/footer.js';
import Container from '@material-ui/core/Container';
import PictureBloc from '../components/blocks/pictureBlocForArt.js';
import * as Services from '../services/apiServices.js';
import { jsPDF } from "jspdf";
import { withRouter} from "react-router-dom";
import getScreenComponent from '../components/screenHandler/screenHandler.js';
import './screens.css';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: 0,
      screenHeight: 0,
      isNewsletterLoaderVisible: false,
      displaySmartphoneVersion: false,
      poi: {},
      isLoading: true,
      lastPoi: [],
      courses: '',
      newPoiId: 0,
      isLoading: true
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener('resize', this._updateDimension);
    this._updateDimension();
    this._getDataFromApi(this.props.match.params.id);

    const courses = await Services.getAllCoursesFromPoi(this.props.match.params.id);
    console.log('COURSES : ' + this.props.match.params.id);
    console.log(courses);
    this.setState({courses: courses});
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this._getDataFromApi(this.state.newPoiId ? this.state.newPoiId : this.props.match.params.id);
    });
  }

  //called when screen is resized
  _updateDimension = () => {
    this.setState({screenWidth: window.innerWidth});
    this.setState({screenHeight: window.innerHeight});
    if (window.innerWidth < 1180) {
      this.setState({displaySmartphoneVersion: true});
    }
    else {
      this.setState({displaySmartphoneVersion: false});
    }
  }

  //used to display new page on screen
  _displayPage = async (page, id) => {
    this.setState({isLoading: true});
    this.setState({newPoiId: id});
    const url = await getScreenComponent(page, id);
    this.props.history.push(url);
  }
  //handle textfields change
  _handleInputChange = (stateName, e) => {
    this.setState({[stateName]: e.target.value});
  }

  _isEmailFormatOk = (email) => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  }

  _handleLoaderState(type) {
    let currentState;
    if (type === 'newsletter') {
      currentState = this.state.isNewsletterLoaderVisible;
    }
    else {
      currentState = this.state.isEmailLoaderVisible;
    }
    this.setState({isNewsletterLoaderVisible: !currentState});
  }

  _sendEmail = () => {
    if (this.state.email === '' || this.state.fullName === '' || this.state.message === '') {
      alert("Merci de renseigner l'ensemble des champs");
      return;
    }
    if (!this._isEmailFormatOk(this.state.email)) {
      alert('Merci de renseigner un email valide');
      return;
    }
    this.setState({isLoaderVisible: true});
  }

  async _getDataFromApi(id) {
    const poi = await Services.getPoiFromId(id);
    console.log('poi');
    console.log(poi);
    this.setState({poi: poi});
    console.log('poi id ' + poi[0].id)
    //get last points of interest in the current point of interest category
    if (poi[0].category === "Fresques") {
      const lastPoi = await Services.getLastMuralPaintingPoi(poi[0].id);
      console.log('lastPoi');
      console.log(lastPoi);
      this.setState({lastPoi: lastPoi}, function() {
        this.setState({state: this.state});
      });
    }
    else {
      const lastPoi = await Services.getLastArtPoi(poi[0].id);
      console.log('lastPoi');
      console.log(lastPoi);
      this.setState({lastPoi: lastPoi}, function() {
        this.setState({state: this.state});
      });
    }
    this.setState({isLoading: false});
  }

  _handleLoaderState = () => {
    const currentState = this.state.isNewsletterLoaderVisible;
    this.setState({isNewsletterLoaderVisible: !currentState});
  }

  render() {
    return(
      this.state.isLoading ? <div style={{display: "flex", justifyContent: "center", marginTop: "50vh", transform: "translateY(-50%)"}}class="lds-circle"><div></div></div> :
      <div className='screenWrapper'>
        <AppBar displayPage={this._displayPage} displaySmartphoneVersion={this.state.displaySmartphoneVersion}/>
          <Title displaySmartphoneVersion={this.state.displaySmartphoneVersion} title={this.state.poi[0].name} subtitle1={this.state.poi[0].address} subtitle2={this.state.courses}/>
          <PictureBloc title={this.state.poi[0].name} displaySmartphoneVersion={this.state.displaySmartphoneVersion} img1={this.state.poi[0].image1} img2={this.state.poi[0].image2} img3={this.state.poi[0].image3} address={this.state.poi[0].address}/>
          <Description displaySmartphoneVersion={this.state.displaySmartphoneVersion} description={this.state.poi[0].description} />
          <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
            <img className="pointer" onClick={() => this._handleDisplayMap()} src={this.state.poi[0].imageMap} alt='' style={this.state.displaySmartphoneVersion ? {width: "100%", borderRadius: "20px", paddingLeft: "40px", paddingRight: "40px"} : {width: "70%", borderRadius: '5px'}}/>
          </div>
          <ArtList lastPoi={this.state.lastPoi} displaySmartphoneVersion={this.state.displaySmartphoneVersion} category={this.state.poi[0].category} displayPage={this._displayPage}/>
        <Footer
          {...this.state}
          handleLoaderState={this._handleLoaderState}
          displayPage={this._displayPage}
          handleInputChange={this._handleInputChange}
          subscribeToNewsletter={this._subscribeToNewsletter}
        />
      </div>
    );
  }
}

export default withRouter (Contact);
